<!--
  我的审核
    + 只含已通过的列表
-->
<template>
  <div class="tabpane_content">
    <div class="content_header">
      <el-input
        v-model="keywords"
        placeholder="请输入机构名称搜索"
        size="small"
        style="width:200px;margin-right: 10px;"
        @keyup.native.enter="onSearch" />
      <el-select
        size="small"
        style="width:200px;margin-right: 10px;"
        v-model="technical"
        placeholder="请选择技术领域"
        clearable
        @change="onSearch">
        <el-option
          v-for="item in technicalList"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select
        size="small"
        style="width:200px;margin-right: 10px;"
        v-model="ap"
        placeholder="请选择所在区域"
        clearable
        filterable
        @change="onSearch">
        <el-option
          v-for="item in staticData.cities"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button
        type="primary"
        plain
        size="small"
        :loading="exportBtnLoading"
        @click="onExport">
        导出
      </el-button>
    </div>
    <div class="content_main">
      <el-table
        v-loading="loading"
        :data="tableData"
        :header-cell-style="headerCellStyle">
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="organizationName"
          label="机构名称">
        </el-table-column>
        <el-table-column
          prop="username"
          label="联系人">
        </el-table-column>
        <el-table-column
          prop="contactNumber"
          label="联系电话">
        </el-table-column>
        <el-table-column
          prop="operatorAt"
          label="审核时间">
        </el-table-column>
      </el-table>
    </div>
    <div class="content_footer">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import { renderExcel } from '@/utils/excel.export.js'
export default {
  data () {
    return {
      loading: false,
      exportBtnLoading: false, // 导出按钮loading
      keywords: '', // 机构名称 关键字
      technical: '', // 技术领域id
      ap: '', // 省份选择器
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: []
    }
  },
  computed: {
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    }
  },
  created () {
    this.getTableData(1)
  },
  methods: {
    /**
     * 状态(审核)， 0 - 待处理, 1 - 已通过, 2 - 已驳回
     */
    getTableData (pageNum) {
      const searchVar = this.getSearchVar()
      this.loading = true
      api.getMyReviewLogByRZ({
        pageNum,
        pageSize: this.pagination.pageSize,
        ...searchVar
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * 导出我的审核入驻机构列表
     */
    onExport () {
      this.exportBtnLoading = true
      const searchVar = this.getSearchVar()
      api.exportMyReviweRzList({
        ...searchVar
      }).then(res => {
        if (res.data.code === 0) {
          console.log('已通过的入驻审核导出', res.data.data)
          const { list, header } = res.data.data
          renderExcel(list, header, '我的审核数据')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.exportBtnLoading = false
      })
    },
    // 搜索
    onSearch () {
      this.getTableData(1)
    },
    // 获取搜索条件
    getSearchVar () {
      return {
        keywords: this.keywords,
        technical: this.technical,
        ap: this.ap
      }
    },
    // 点击行
    rowClick (row) {
      const { href } = this.$router.resolve({
        path: `/account-manage/rz-audit/detail/${row.id}`
      })
      window.open(href, '_blank')
    },
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    },
    pageNumChange (page) {
      this.getTableData(page)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tabpane_content {
    padding: 15px 25px;
    .content_header {
      display: flex;
      margin-bottom: 20px;
    }
    .content_main {
      margin-bottom: 20px;
    }
    .content_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
  }
</style>
