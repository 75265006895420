<!-- 服务机构账号审核 -->
<template>
  <div class="card">
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane label="待处理" name="1" lazy>
        <pending-table></pending-table>
      </el-tab-pane>
      <el-tab-pane label="已通过" name="2" lazy>
        <accepted-table></accepted-table>
      </el-tab-pane>
      <el-tab-pane label="我的审核" name="3" lazy>
        <myreview-table></myreview-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import pendingTable from './components/pending-table'
import acceptedTable from './components/accepted-table'
import MyreviewTable from './components/myreview-table.vue'

export default {
  components: {
    pendingTable,
    acceptedTable,
    MyreviewTable
  },
  data () {
    return {
      activeTab: '1'
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    width: 980px;
    border-radius: 4px;
  }
</style>
